import { combineReducers } from 'redux';
import authreducer from '../reducers/auth';
import orderreducer from '../reducers/order';

const appReducer = combineReducers({
//     // user,
    authreducer: authreducer,
    orderreducer: orderreducer,


});

const rootReducer = (state, action) => {
  return appReducer(state, action)
}

export default rootReducer