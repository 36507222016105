import { LOGIN_SUCCESS, LOGOUT } from "../actions/types"

const initialState = {
    token: '',
    isLoggedIn: false,
    tokenData: localStorage.getItem('tokenData')
}

export default function (state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
        case LOGIN_SUCCESS:
            localStorage.setItem('tokenData', payload.token)
            return {
                ...state,
                ...payload,
                tokenData:payload.token,
                isLoggedIn: true,
            };
        case LOGOUT:
            return {
                ...state,
                ...action.payload,
                isLoggedIn: false,
                tokenData:''
            };
        default:
            return state
    }
}