export const SHOW_LOADING = "SHOW_LOADING";

export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGOUT = "LOGOUT";
export const SHOW_TOAST = "SHOW_TOAST";
export const NOTIFICATION_ALERT = "NOTIFICATION_ALERT";

//users type
export const GET_USER_DATA = "GET_USER_DATA";
export const DELETE_USER = "DELETE_USER";
export const USER_DETAIL = "USER_DETAIL";
export const GET_USER_DATA_BY_DATE = "GET_USER_DATA_BY_DATE";

//dashboard type
export const GET_DASHBOARD_DATA = "GET_DASHBOARD_DATA";
export const ORDER_DETAIL = "ORDER_DETAIL";
export const UPDATE_STATUS = "UPDATE_STATUS";
export const DELETE_ORDERS = "DELETE_ORDERS";

//categories type
export const GET_CATEGORIES = "GET_CATEGORIES";
export const DELETE_CATEGORY = "DELETE_CATEGORY";
export const ADD_CATEGORY = "ADD_CATEGORY";
export const EDIT_CATEGORY = "EDIT_CATEGORY";

//subcategories type
export const GET_SUB_CATEGORIES = "GET_CATEGORIES";
export const DELETE_SUB_CATEGORY = "DELETE_CATEGORY";
export const ADD_SUB_CATEGORY = "ADD_SUB_CATEGORY";
export const EDIT_SUB_CATEGORY = "EDIT_SUB_CATEGORY";
export const LOAD_SUB_CATEGORY = "LOAD_SUB_CATEGORY";

//products type
export const GET_PRODUCTS = "GET_PRODUCTS";
export const DELETE_PRODUCTS = "DELETE_PRODUCTS";
export const ADD_PRODUCT = "ADD_PRODUCT";
export const EDIT_PRODUCTS = "EDIT_PRODUCTS";
export const GET_SINGLE_PRODUCTS = "GET_SINGLE_PRODUCTS";

//offer type
export const OFFER_LIST = "OFFER_LIST";
export const DELETE_OFFER = "DELETE_OFFER";
export const ADD_OFFER = "ADD_OFFER";
export const EDIT_OFFER = "EDIT_OFFER";

//order type
export const ORDER_LIST = "ORDER_LIST"
export const USER_BASED_ORDER_LIST = "USER_BASED_ORDER_LIST"

//notification type
export const NOTIFICATION_LIST = "NOTIFICATION_LIST"
export const DELETE_NOTIFICATION = "DELETE_NOTIFICATION"
export const NOTIFICATION_DETAIL = "NOTIFICATION_DETAIL"
export const SEND_SINGLE_NOTIFICATION = "SEND_SINGLE_NOTIFICATION"
export const SEND_ALL_NOTIFICATION = "SEND_ALL_NOTIFICATION"

//banner list
export const BANNER_LIST = "BANNER_LIST"
export const ADD_BANNER = "ADD_BANNER"
export const DELETE_BANNER = "DELETE_BANNER"
export const UPDATE_BANNER = "UPDATE_BANNER"

//USER FEEDBACK
export const GET_FEEDBACK = "GET_FEEDBACK"
export const DELETE_FEEDBACK = "DELETE_FEEDBACK"
export const GET_SINGLE_FEEDBACK = "GET_SINGLE_FEEDBACK"

// ORDER REDUCERS ACTIONS
export const SET_SELECTED_ORDER = "SET_SELECTED_ORDER"




