import {SET_SELECTED_ORDER } from "../actions/types"

const initialState = {
    token: '',
    isLoggedIn: false,
    tokenData: localStorage.getItem('tokenData'),
    selectedOrder : {}
}

export default function (state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
        case SET_SELECTED_ORDER:
            // localStorage.setItem('tokenData', payload.token)
            return {
                ...state,
                ...payload,
                selectedOrder:payload.record,                
            };
        default:
            return state
    }
}